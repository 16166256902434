<template>
  <el-dialog :visible="dialogVisible" @close="closeDialog" :show-close="false">
    <div slot="title" class="left_top">
      <div class="left_icon"><span></span> <span>新建企业</span></div>
    </div>
    <el-form
      label-position="top"
      width="900px"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="ruleForm"
      label-width="80px"
    >
      <el-divider content-position="left">基本信息</el-divider>
      <el-form-item label="企业名称" prop="name">
        <el-input v-model="ruleForm.name"></el-input>
      </el-form-item>

      <el-form-item label="统一信用编码" prop="idCardNumber">
        <el-input v-model="ruleForm.idCardNumber"></el-input>
      </el-form-item>

      <el-form-item label="营业执照照片" prop="idCardImage">
        <Upload v-model="ruleForm.idCardImage" path="/enterprise.upload.json">
          上传
        </Upload>
      </el-form-item>
      <el-form-item label="管理员手机号" prop="phone">
        <el-input v-model="ruleForm.phone"></el-input>
      </el-form-item>

      <el-divider content-position="left">法人信息</el-divider>
      <el-form-item label="法人姓名" prop="juridicalPersonName">
        <el-input v-model="ruleForm.juridicalPersonName"></el-input>
      </el-form-item>

      <el-form-item label="法人身份证号" prop="juridicalPersonId">
        <el-input v-model="ruleForm.juridicalPersonId"></el-input>
      </el-form-item>

      <el-form-item label="法人身份证正面" prop="juridicalPersonIdFont">
        <Upload
          v-model="ruleForm.juridicalPersonIdFont"
          path="/enterprise.upload.json"
        >
          上传
        </Upload>
      </el-form-item>

      <el-form-item label="法人身份证反面" prop="juridicalPersonIdBack">
        <Upload
          v-model="ruleForm.juridicalPersonIdBack"
          path="/enterprise.upload.json"
        >
          上传
        </Upload>
      </el-form-item>
      <el-divider content-position="left">开户信息</el-divider>

      <!-- <el-form-item label="E签保账号" prop="esignAccount">
        <el-input v-model="ruleForm.esignAccount"></el-input>
      </el-form-item>

      <el-form-item label="E签保认证流程id" prop="esignFlowId">
        <el-input v-model="ruleForm.esignFlowId"></el-input>
      </el-form-item>

      <el-form-item label="网商账号" prop="mybankAccount">
        <el-input v-model="ruleForm.mybankAccount"></el-input>
      </el-form-item> -->

      <el-form-item label="银行" prop="bankName">
        <el-input v-model="ruleForm.bankName"></el-input>
      </el-form-item>

      <el-form-item label="银行卡号" prop="bankCode">
        <el-input v-model="ruleForm.bankCode"></el-input>
      </el-form-item>

      <el-form-item label="开户地" prop="bankArea">
        <el-input v-model="ruleForm.bankArea"></el-input>
      </el-form-item>

      <el-form-item label="支行" prop="bankBranch">
        <el-input v-model="ruleForm.bankBranch"></el-input>
      </el-form-item>

      <el-form-item label="支行联行号" prop="bankBranchCode">
        <el-input v-model="ruleForm.bankBranchCode"></el-input>
      </el-form-item>

      <!-- <el-form-item label="短信限制" prop="smsLimit">
        <el-input-number
          v-model="ruleForm.smsLimit"
          :min="0"
          label="描述文字"
        ></el-input-number>
      </el-form-item>

      <el-form-item label="合同计数" prop="contractLimit">
        <el-input-number
          v-model="ruleForm.contractLimit"
          :min="0"
          label="描述文字"
        ></el-input-number>
      </el-form-item>

      <el-form-item label="创客认证计数" prop="makerVertify">
        <el-input-number
          v-model="ruleForm.makerVertify"
          :min="0"
          label="描述文字"
        ></el-input-number>
      </el-form-item>

      <el-form-item label="创建时间" prop="createTime">
        <el-date-picker
          type="date"
          placeholder="选择日期"
          v-model="ruleForm.createTime"
          style="width: 100%"
        >
        </el-date-picker>
      </el-form-item> -->
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm('ruleForm')"
        >确 定</el-button
      >
      <el-button @click="closeDialog()">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { api } from '/src/api/base';
const request = api('/enterprise');
export default {
  components: {
    Upload: () => import('/src/components/upload.vue')
  },
  data() {
    return {
      ruleForm: {
        // code: "",

        name: '',

        idCardNumber: '',

        idCardImage: '',

        juridicalPersonName: '',

        juridicalPersonId: '',

        juridicalPersonIdFont: '',

        juridicalPersonIdBack: '',

        // esignAccount: "",

        // esignFlowId: "",

        // mybankAccount: "",

        bankName: '',

        bankCode: '',

        bankArea: '',

        bankBranch: '',

        bankBranchCode: '',

        // smsLimit: 0,

        // contractLimit: 0,

        // makerVertify: 0,

        // isAuthenticated: false,

        // createTime: ""
        phone: ''
      },
      rules: {
        code: [],

        name: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],

        idCardNumber: [
          { required: true, message: '请输入统一信用编码', trigger: 'blur' }
        ],

        idCardImage: [
          { required: true, message: '请输入营业执照照片', trigger: 'blur' }
        ],

        phone: [
          { required: true, message: '请输入管理员手机号', trigger: 'blur' }
        ],

        juridicalPersonName: [
          { required: true, message: '请输入法人姓名', trigger: 'blur' }
        ],

        juridicalPersonId: [
          { required: true, message: '请输入法人身份证号', trigger: 'blur' }
        ],

        juridicalPersonIdFont: [
          { required: true, message: '请输入法人身份证正面', trigger: 'blur' }
        ],

        juridicalPersonIdBack: [
          { required: true, message: '请输入法人身份证反面', trigger: 'blur' }
        ],

        esignAccount: [
          { required: true, message: '请输入E签保账号', trigger: 'blur' }
        ],

        esignFlowId: [
          { required: true, message: '请输入E签保认证流程id', trigger: 'blur' }
        ],

        mybankAccount: [
          { required: true, message: '请输入网商账号', trigger: 'blur' }
        ],

        bankName: [{ required: true, message: '请输入银行', trigger: 'blur' }],

        bankCode: [
          { required: true, message: '请输入银行卡号', trigger: 'blur' }
        ],

        bankArea: [
          { required: true, message: '请输入开户地', trigger: 'blur' }
        ],

        bankBranch: [
          { required: true, message: '请输入支行', trigger: 'blur' }
        ],

        bankBranchCode: [
          { required: true, message: '请输入支行联行号', trigger: 'blur' }
        ],

        smsLimit: [
          { required: true, message: '请输入短信限制', trigger: 'blur' }
        ],

        contractLimit: [
          { required: true, message: '请输入合同计数', trigger: 'blur' }
        ],

        makerVertify: [
          { required: true, message: '请输入创客认证计数', trigger: 'blur' }
        ],

        isAuthenticated: [],

        createTime: []
      }
    };
  },
  props: {
    dialogVisible: Boolean
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          request('enterprise.add.json')(this.ruleForm).then(res => {
            console.log(res);
            this.closeDialog();
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    closeDialog() {
      this.$emit('update:dialogVisible', false);
      this.$emit('func', this.dialogVisible);
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ruleForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.el-form-item {
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
  width: 49%;
}

.el-form-item__label {
  padding-bottom: 0 !important;
}
</style>
